export enum FeatureFlags {
  CHATBOT_ENABLED = 'chatbot-enabled',
  ELEVATE_ESCALATION_ENABLED = 'elevate-escalation-enabled',
  QA_SFDC_EMAIL_DOMAIN_CHECK_DISABLED = 'qa-sfdc-email-domain-check-disabled',
  DOCSAI_SEARCH_ENABLED = 'docsai-search-enabled',
  KNOWN_ISSUES_ENABLED = 'known-issues-enabled',
  DOCSAI_REGENERATE_RESPONSE_ENABLED = 'docsai-regenerate-response-enabled',
  CLOUD_ENABLED = 'cloud-enabled',
  ENTITY_MANAGEMENT_ENABLED = 'entity-management-enabled',
  STATUS_UPDATES_ENABLED = 'status-updates-enabled',
  DYNAMIC_OVERAGE_FOR_ELA_ENABLED = 'dynamic-overage-for-ela-enabled',
  LICENSE_MULTI_YEAR_PROVISION_ENABLED = 'multi-year-license-provision-enabled',
  DOCSAI_ALL_LANGUAGES_ENABLED = 'docsai-all-languages-enabled',
}
